<script lang="ts">
  import BetweenCurlyBraces from '@components/BetweenCurlyBraces/BetweenCurlyBraces.svelte';
  import CareersSection from '@components/Careers/CareersSection/CareersSection.svelte';
  import { pathOr, sortBy } from 'ramda';

  import TerminalWindow from '../../_components/TerminalWindow/TerminalWindow.svelte';
  import { type CrewItemSchemaType } from './crew.const.ts';

  export let data: Array<CrewItemSchemaType> = [];

  // It should return '@firstName'
  const toLinkedInLabel = (name: string) => {
    const firstName = name.split(' ')[0];
    return `@${firstName}`;
  };

  const dataFormatted = sortBy(pathOr(100, ['attributes', 'order']))(data);
</script>

<CareersSection sectionCss="bg-light-gray" floatingLabel="OUR TEAM" floatingLabelCss="text-white">
  <h2
    id="tech-crew"
    class="section-title col-span-11 lg:col-span-5 flex lg:flex-col text-white leading-none"
  >
    <span class="text-careers-basic-secondary">FLIP</span>
    <span>Tech</span>
    <span>crew</span>
  </h2>

  <BetweenCurlyBraces
    parentPropertyName=""
    css="max-w-md lg:ml-auto text-xl lg:text-2xl lg:col-start-7 col-span-12 lg:col-span-5"
  >
    Flip is a place where <span class="text-green-main">individualises come together</span>, forming
    a cohesive unit ready to <span class="text-careers-purple">tackle challenges.</span>
    Get to know the people who drive our momentum and serve
    <span class="text-careers-orange">as inspiration each day.</span>
  </BetweenCurlyBraces>

  <div class="w-full col-span-12 lg:col-span-10">
    <TerminalWindow>
      <BetweenCurlyBraces parentPropertyName="tech_leadership_team:" as="array">
        {#each dataFormatted as member}
          <BetweenCurlyBraces parentPropertyName="">
            <div>
              <span>
                <span class="text-green-main">name:</span>
                "{member.attributes.name}"
              </span>
            </div>

            <div>
              <span>
                <span class="text-careers-purple">role:</span>
                "{member.attributes.role}"
              </span>
            </div>

            <div>
              <span>
                <span class="text-careers-orange">linkedin_profile:</span>
                <a
                  href={member.attributes.linkedin}
                  target="_blank"
                  referrerpolicy="no-referrer"
                  class="underline"
                >
                  "{toLinkedInLabel(member.attributes.name)}"
                </a>
              </span>
            </div>
          </BetweenCurlyBraces>
        {/each}
      </BetweenCurlyBraces>
      <span class="text-careers-basic-secondary">...///</span>
    </TerminalWindow>
  </div>
</CareersSection>
