<script lang="ts">
  let terminalWindow: HTMLElement;

  $: terminalWindowHeight = terminalWindow?.clientHeight;
  $: linesToRender = Math.floor(terminalWindowHeight / 24);
</script>

<div class="w-full flex flex-col rounded-3xl overflow-hidden border border-[#525252]">
  <div class="w-full bg-[#3B3B3B] p-6 border-b border-[#525252]">
    <!--    Dots-->
    <div class="flex items-center gap-2">
      <div class="dot bg-careers-red"></div>
      <div class="dot bg-careers-yellow"></div>
      <div class="dot bg-careers-green"></div>
    </div>
  </div>

  <div class="w-full bg-black-carbon p-4 lg:p-10 font-source-pro flex gap-3 lg:gap-6 !text-base">
    <div class="flex flex-col text-careers-basic-secondary font-source-pro">
      {#each { length: linesToRender } as _, index}
        <div class="h-6">{index + 1}</div>
      {/each}
    </div>
    <div class="w-full" bind:this={terminalWindow}>
      <slot />
    </div>
  </div>
</div>

<style lang="scss">
  .dot {
    @apply h-3 w-3 rounded-full;
  }
</style>
